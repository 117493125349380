import React, { ReactNode } from "react";

import { InstallmentLoansCard, PaydayLoansCard } from "@brainfinance/icash-component-library";
import defaultImg from "@images/default.png";

export const BorrowMoney = (props: React.PropsWithChildren<{
  title: ReactNode;
  description: string;
  onClickPaydayGetStarted?: () => void;
  onClickPaydayLearnMore?: () => void;
  onClickInstallmentGetStarted?: () => void;
  onClickInstallmentLearnMore?: () => void;
  paydayImgSrc: string;
  paydayImgSrcSet: string;
  installmentImgSrc: string;
  installmentImgSrcSet: string;
}>) => (
  <div className="mt-[128px] mx-auto flex flex-col items-center px-[22px] mb-[154px] md:mb-[66px] md:mt-[44px] sm:items-start sm:mb-[44px]">
    {props.title}
    <div className="global--paragraph mb-[88px] md:mb-[33px] md:max-w-[320px] text-center sm:text-left">
      {props.description}
    </div>
    <PaydayLoansCard
      className="mb-[88px] md:mb-[55px]"
      onClickGetStarted={props.onClickPaydayGetStarted}
      onClickLearnMore={props.onClickPaydayLearnMore}
    >
      <img className="w-auto h-[inherit]" src={props.paydayImgSrc} srcSet={props.paydayImgSrcSet} alt="payday-loans" />
    </PaydayLoansCard>
    <InstallmentLoansCard
      onClickGetStarted={props.onClickInstallmentGetStarted}
      onClickLearnMore={props.onClickInstallmentLearnMore}
    >
      <img className="w-auto h-[inherit]" src={props.installmentImgSrc}  srcSet={props.installmentImgSrcSet} alt="installment-loans" />
    </InstallmentLoansCard>
  </div>
);


BorrowMoney.defaultProps = {
  title: (
    <h1 className="global--page-heading mb-[22px] text-center md:mb-[16px] md:max-w-[460px] sm:max-w-[270px] sm:text-left">
      The easiest way to&nbsp;
      <span className="text-brand-green">borrow money</span>&nbsp;online
    </h1>
  ),
  description: 'Take control of your finances with financial products designed just for you.',
  paydayImgSrc: defaultImg,
  installmentImgSrc: defaultImg,
  onClickPaydayGetStarted: undefined,
  onClickPaydayLearnMore: undefined,
  onClickInstallmentGetStarted: undefined,
  onClickInstallmentLearnMore: undefined,
  paydayImgSrcSet: '',
  installmentImgSrcSet: '',
};