export const QUESTIONS_DATA = [
  {
    question: "How much does Credit Builder cost?",
    answer:
      "Facilisis lectus nec, dignissim justo. Praesent facilisis aliquam elit, suscipit dignissim neque sodales et. Quisque sapien nisl, porta eget blandit at, ullamcorper non metus. Maecenas mattis pharetra scelerisque."
  },
  {
    question: "How can I access my available funds?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed sapien congue, facilisis lectus nec, dignissim justo. Praesent facilisis aliquam elit, suscipit dignissim neque sodales et. Quisque sapien nisl, porta eget blandit at, ullamcorper non metus. Maecenas mattis pharetra scelerisque."
  },
  {
    question: "How long do I have to make up a missed payment?",
    answer:
      "Praesent facilisis aliquam elit, suscipit dignissim neque sodales et. Quisque sapien nisl, porta eget blandit at, ullamcorper non metus. Maecenas mattis pharetra scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed sapien congue, facilisis lectus nec, dignissim justo."
  },
  {
    question: "How do I make up a missed payment?",
    answer:
      "Etiam sed sapien congue, lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis lectus nec, dignissim justo. Praesent facilisis aliquam elit, suscipit dignissim neque sodales et. Quisque sapien nisl, porta eget blandit at, ullamcorper non metus. Maecenas mattis pharetra scelerisque."
  },
  {
    question: "Can I put my payments on hold?",
    answer:
      "Suscipit dignissim neque sodales et. Quisque sapien nisl, porta eget blandit at, ullamcorper non metus lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed sapien congue, facilisis lectus nec, dignissim justo. Praesent facilisis aliquam elit. Maecenas mattis pharetra scelerisque."
  }
];
