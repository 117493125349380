import React from "react";
import defaultImg from "@images/default.png";
import { Button } from "@brainfinance/icash-component-library";
import ios from "@images/iOS-light.svg";

export const ApplyDownload = () => (
  <div className="relative pt-[110px] pb-[213px] px-[22px] bg-primary text-interface-50 md:px-[44px] md:pt-[44px] md:pb-[161px] sm:px-[22px] sm:py-[44px]">
    <div className="max-w-[1320px] mx-auto md:max-w-full">
      <div className="global--heading relative z-[1] mb-[22px] max-w-[660px] md:max-w-[468px] md:mb-[16px] sm:max-w-full">
        Borrow money anywhere at any time
      </div>
      <div className="global--paragraph relative z-[1] mb-[64px] max-w-[660px] md:max-w-[468px] md:mb-[77px] sm:max-w-full sm:mb-[33px]">
        iCash has helped over 500,000 customers get e-Transfer payday loans in Canada 24/7 without hassle.
      </div>
      <img className="h-full absolute right-0 top-0 sm:static sm:h-auto sm:mx-auto sm:mb-[33px]" src={defaultImg} />
      <div className="flex items-center relative z-[1] sm:flex-col-reverse sm:justify-center">
        <Button
          className="mr-[16px] sm:mr-0 sm:w-full"
          size="large"
          appearance="alt"
          onClick={() => {}}
        >
          Apply now
        </Button>
        <Button
          className="border-interface-50 text-interface-50 md:hidden"
          appearance="tertiary"
          size="large"
          onClick={() => {}}
        >
          Download now
        </Button>
        <a className="hidden md:inline-block sm:mb-[33px]" href="https://icash-ca.app.link/URLVQwAR9X" target="_blank" rel="noreferrer">
          <img className="h-[44px] w-[131px] float-left" src={ios} alt="Logo iOS" />
        </a>
      </div>
    </div>
  </div>
);
