import React, { ReactNode } from "react";
import { FinanceStats } from "@brainfinance/icash-component-library";
import { useScreenSize } from "../../helpers";

export const FinancialStats = (props: React.PropsWithChildren<{
  title: ReactNode;
}>) => {
  const screenSize = useScreenSize();
  
  return (
    <div className="w-full min-w-fit flex justify-center bg-[radial-gradient(#E4E4E4_-10%,_#F7F7F7)] sm:bg-none sm:bg-interface-100 sm:px-[22px] sm:py-[44px]">
      <div className="flex flex-wrap bg-transparent py-[99px] w-[1364px] lg:w-auto lg:pt-[66px] lg:pb-[88px] sm:p-0 sm:flex-col sm:w-full">
        {props.title}
        <FinanceStats
          className="mt-[22px] mb-[33px] grow lg:w-[70%] lg:grow-0 lg:mt-[44px] sm:w-full sm:mb-[11px]"
          title="$61 Millions"
          titleColor="#FF005C"
          description="saved by iCash customers since 2016"
          align={screenSize !== 'lg' && screenSize !== 'md' ? 'left' : 'right'}
        />
        <FinanceStats
          className="w-[40%] mr-[33px] mb-[33px] sm:w-full sm:mb-[11px]"
          title="30,000+"
          titleColor="#00A5FF"
          description="positive reviews"
          align="right"
        />
        <FinanceStats
          className="grow mb-[33px] sm:w-full sm:mb-[11px]"
          title="$5,700,000+"
          titleColor="#FFBC00"
          description="given in Cashback"
        />
        <FinanceStats
          className="w-[calc(60%_-_33px)] mr-[33px] sm:w-full sm:mb-[11px]"
          title="1.1 Millions"
          titleColor="#F88DFD"
          description="loans issued since 2016"
          align="right"
        />
        <FinanceStats
          className="grow sm:w-full sm:grow-0"
          title="520,000"
          titleColor="#00A5FF"
          description="downloads of iCash App"
        />
      </div>
    </div>
  );
};

FinancialStats.defaultProps = {
  title: (
    <div className="global--heading w-1/2 min-w-[200px] pr-[140px] lg:!text-[30px] lg:!leading-[31px] lg:!tracking-[-1px] lg:w-full lg:pl-[242px] sm:p-0">
      We help our members
      <br/>
      to get their finances
      <br/>
      <span className="text-brand-green">back on track</span>
    </div>
  ),
};