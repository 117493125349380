import * as React from "react";

import { PageLayout, SEO } from "@components/page-layout";
import { graphql, navigate } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { BorrowMoney } from "@containers/borrow-money";
import { FinancialStats } from "@containers/financial-stats";
import { ICashAdvantages } from "@containers/icash-advantages";
import { PartnersSection } from "@containers/partners";
import { Testimonials } from "@containers/testimonials";
import { WhyICash } from "@containers/why-icash";
import { MainHeaderContainer } from "@containers/header";
import { TrustPilotWidget } from "@containers/trust-pilot-widget";
import { FrequentlyAskedQuestions } from "@containers/frequently-asked-questions";
import { ApplyDownload } from "@containers/apply-download";
import { HowInstantLoansWork } from "@containers/how-instant-loans-work";
import { Disclaimer } from "@containers/disclaimer";

const IndexPage = (props: any) => (
  <PageLayout>
    <SEO title="Home" />
    <MainHeaderContainer
      supParagraph="Take care of whatever life throws at you. Get an Instant approval for up to $1,500. Fast e-Transfers within minutes of signing up."
      subParagraph="Available 24/7 accross Canada. Apply online or download our app to get started."
      src={getImage(props.data.headerFile)?.images.fallback?.src || ""}
      srcSet={getImage(props.data.headerFile)?.images.fallback?.srcSet || ""}
      bgColor="bg-[#FFCEE7]"
    >
      Fast online payday loans in Canada
    </MainHeaderContainer>
    <BorrowMoney
      onClickPaydayLearnMore={() => navigate('/instant-loans')}
      onClickPaydayGetStarted={() => navigate('/signup')}
      onClickInstallmentLearnMore={() => navigate('/personal-loans')}
      paydayImgSrc={getImage(props.data.paydayFile)?.images.fallback?.src}
      paydayImgSrcSet={getImage(props.data.paydayFile)?.images.fallback?.srcSet || ""}
      installmentImgSrc={getImage(props.data.installmentFile)?.images.fallback?.src}
      installmentImgSrcSet={getImage(props.data.installmentFile)?.images.fallback?.srcSet || ""}
    />
    <Disclaimer />
    <div className="py-[44px] md:py-[22px]">
      <PartnersSection />
    </div>
    <HowInstantLoansWork />
    <TrustPilotWidget />
    <ICashAdvantages
      onClick={() => navigate('/why-icash')}
      fastApplicationImg={getImage(props.data.fastApplicationFile)?.images.fallback?.src}
      noDocumentImg={getImage(props.data.noDocumentFile)?.images.fallback?.src}
      noHiddenImg={getImage(props.data.noHiddenFile)?.images.fallback?.src}
      instantImg={getImage(props.data.instantFile)?.images.fallback?.src}
      eTransferImg={getImage(props.data.eTransferFile)?.images.fallback?.src}
      cashbackImg={getImage(props.data.cashbackFile)?.images.fallback?.src}
      fastApplicationImgSrcSet={getImage(props.data.fastApplicationFile)?.images.fallback?.srcSet || ""}
      noDocumentImgSrcSet={getImage(props.data.noDocumentFile)?.images.fallback?.srcSet || ""}
      noHiddenImgSrcSet={getImage(props.data.noHiddenFile)?.images.fallback?.srcSet || ""}
      instantImgSrcSet={getImage(props.data.instantFile)?.images.fallback?.srcSet || ""}
      eTransferImgSrcSet={getImage(props.data.eTransferFile)?.images.fallback?.srcSet || ""}
      cashbackImgSrcSet={getImage(props.data.cashbackFile)?.images.fallback?.srcSet || ""}
    />
    <FinancialStats />
    <WhyICash
      secureImg={getImage(props.data.secureFile)?.images.fallback?.src}
      helpImg={getImage(props.data.helpFile)?.images.fallback?.src}
      benefitsImg={getImage(props.data.benefitsFile)?.images.fallback?.src}
      secureImgSrcSet={getImage(props.data.secureFile)?.images.fallback?.srcSet || ""}
      helpImgSrcSet={getImage(props.data.helpFile)?.images.fallback?.srcSet || ""}
      benefitsImgSrcSet={getImage(props.data.benefitsFile)?.images.fallback?.srcSet || ""}
    />
    <Testimonials />
    <FrequentlyAskedQuestions />
    <ApplyDownload />
  </PageLayout>
);

export default IndexPage;

export const pageQuery = graphql`
  query {
    headerFile: file(relativePath: { eq: "home-bg.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1160)
      }
    }
    paydayFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 552)
      }
    }
    installmentFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 552)
      }
    }
    fastApplicationFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    noDocumentFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    noHiddenFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    instantFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    eTransferFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    cashbackFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 236)
      }
    }
    secureFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 425)
      }
    }
    helpFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 425)
      }
    }
    benefitsFile: file(relativePath: { eq: "default.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 425)
      }
    }
  }
`;
