import React, { ReactNode } from "react";
import { ImageCard } from "@brainfinance/icash-component-library";

import defaultImg from "@images/default.png";

export const WhyICash = (props: React.PropsWithChildren<{
  title: ReactNode;
  secureImg: string;
  helpImg: string;
  benefitsImg: string;
  secureImgSrcSet: string;
  helpImgSrcSet: string;
  benefitsImgSrcSet: string;
  onClickNeedHelp: () => void;
  onClickCashback: () => void;
  onClickFinancialAdvices: () => void;
}>) => (
  <div className="max-w-[1410px] mx-auto py-[88px] my-[44px] px-[22px] md:pt-[44px] md:pb-[66px] md:mb-0 sm:pb-[44px] sm:my-[22px]">
    {props.title}
    <div className="flex sm:flex-wrap">
      <ImageCard
        className="w-1/3 sm:w-full sm:mb-[22px]"
        title="Secure service and Data protection"
        imgSrc={props.secureImg}
        imgSrcSet={props.secureImgSrcSet}
      >
        <div className="global--paragraph sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          Our encryption system is the same as the one used by banks and we do not sell any of your personal informations to third parties.
        </div>
      </ImageCard>
      <ImageCard
        className="w-1/3 sm:w-full sm:mb-[22px]"
        title="Help when you need it"
        imgSrc={props.helpImg}
        imgSrcSet={props.helpImgSrcSet}
      >
        <div className="global--paragraph sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          <span className="global--link" onClick={props.onClickNeedHelp}>Need help?</span>
          &nbsp;Get in touch with our support team via phone or chat.
        </div>
      </ImageCard>
      <ImageCard
        className="w-1/3 sm:w-full"
        title="Complimentary member benefits"
        imgSrc={props.benefitsImg}
        imgSrcSet={props.benefitsImgSrcSet}
      >
        <div className="global--paragraph sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          iCash members get benefits like&nbsp;
          <span className="global--link" onClick={props.onClickCashback}>cashback</span>
          &nbsp;and&nbsp;
          <span className="global--link" onClick={props.onClickFinancialAdvices}>financials advices</span>
        </div>
      </ImageCard>
    </div>
  </div>
);

WhyICash.defaultProps = {
  title: (
    <div className="global--heading text-interface-500 mb-[66px] text-center md:mb-[33px]">
      Why do&nbsp;
      <span className="text-brand-green">600k members</span>
      &nbsp;love iCash?
    </div>
  ),
  secureImg: defaultImg,
  helpImg: defaultImg,
  benefitsImg: defaultImg,
  secureImgSrcSet: '',
  helpImgSrcSet: '',
  benefitsImgSrcSet: '',
  onClickNeedHelp: () => {},
  onClickCashback: () => {},
  onClickFinancialAdvices: () => {},
};