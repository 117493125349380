import React from "react";
import { FaqPreview, FaqPreviewAnswer } from "@brainfinance/icash-component-library";
import { QUESTIONS_DATA } from "./questions-data";
import { generateTitle } from "@helpers/generateTitle";

export const FrequentlyAskedQuestions = () => (
  <div className="px-[22px] flex flex-col items-center md:max-w-[540px] md:mx-auto sm:px-0">
    <FaqPreview className="my-[88px] md:my-[44px]">
      <div className="mb-[44px] md:mb-[22px]">
        {generateTitle({
          title: 'Frequently asked questions',
          green: 'questions',
        })}
      </div>
      {QUESTIONS_DATA.map((item, ndx) => (
        <FaqPreviewAnswer key={ndx} question={item.question}>
          {item.answer}
        </FaqPreviewAnswer>
      ))}
      <p className="global--caption pt-[4rem]">
        <span className="text-interface-300">Have more questions?</span>{" "}
        <a href="/faq" className="global--link" target="_blank">
          Read our full FAQ
        </a>
      </p>
    </FaqPreview>
  </div>
);
